



























import Vue from 'vue';
import { IPostFields } from '../../@types/generated/contentful';
import { BLOCKS, NodeData } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Fields } from 'src/types/interface';

export default Vue.extend({
  name: 'Post',
  props: ['postId'],
  data() {
    return {
      richText: '',
      data: {
        title: '',
        content: {},
        image: {
          fields: {
            file: {
              url: '',
            },
          },
        },
        tags: {
          fields: {},
        },
      } as IPostFields,
    };
  },
  created() {
    this.getPost();
  },
  methods: {
    async getPost() {
      if (this.postId) {
        await this.$contentful
          .getEntry<IPostFields>(this.postId)
          .then(data => {
            // console.log(data);
            const options: NodeData = {
              renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: ({
                  data: {
                    target: { fields },
                  },
                }: Fields) =>
                  `<img src="${fields.file.url}"
                   height="100%"
                   width="100%"
                   alt="${fields.description}"/>`,
              },
            };
            this.richText = documentToHtmlString(data.fields.content, options);
            this.data = data.fields;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
  },
});
